<template>
    <div class="baseInfo scrolling">
        <formTable v-if="+type == 1" :myData="myData" />
        <fastTable v-else-if="+type == 2" :myData="myData" />
        <billTable v-else-if="+type == 3" :myData="myData" />
    </div>
</template>
<script>
import upload from '@/views/fileStream/documentManage/upload'
import formTable from '../../components/formTable'
import billTable from '../../components/formTable/billTable.vue'
import fastTable from '../../components/formTable/fastTable.vue'
export default {
    components: {
        formTable,
        upload,
        fastTable,
        billTable,
    },
    props: {
        myData: {
            type: Object,
            default() {
                return {}
            },
        },
        files: {
            type: Array,
            default() {
                return []
            },
        },
        type: {
            type: String | Number,
            default: 1,
        },
    },
}
</script>
<style lang="less" scoped>
.baseInfo {
    .flex {
        display: flex;
        align-items: center;
    }
    .title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #1c1c1c;
        padding-left: 5px;
        margin-bottom: 8px;
        border-left: 2px solid #3b9afa;
        line-height: 1;
    }
    .files {
        padding-left: 5px;
        padding-bottom: 6px;
        .file {
            margin-bottom: 8px;
        }
        .imgs {
            width: 13px;
            height: 13px;
            font-size: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .fileName {
            line-height: 1;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #3b9afa;
        }
    }
}
</style>
